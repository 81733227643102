import { Link } from '../extraFunctions/Link';
import './Button.scss';

export const Button = ({type, className, style, children, ...otherProps}) => {
    return (
        <button 
            type={type?type:'button'}
            className={className? className:'action-button'}
            style={style? style: null}
            {...otherProps}
        >
            {children}
        </button>
    )
}

export const ButtonTakeMeToUrl = ({ takeMeTo, className, style, children }) =>{
    return (
        <Link 
            to={takeMeTo.to} 
            hostname={takeMeTo.hostname} 
            http={takeMeTo.http}
            className={className? className:'action-button'}
            style={style? style: null}
        >
            {children}
        </Link>
    )
}