import React, { useState, useEffect } from 'react';
import './Carousel.scss';
import { Button } from '../../button/Button';

const Carousel = ({ slides, interval = 3000, slideChangeButton }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;

    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, interval);
    return () => clearInterval(timer);
  }, [slides.length, interval, isPaused]);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  // console.log("Slides: ", slides);
  return (
    <div className="carousel">
        {
            slideChangeButton?
                <Button className='button-carousel light-button' onClick={prevSlide}>
                    <i className="bi bi-caret-left-fill"></i>        
                </Button>
            :null
        }
      {/* <Button className='button-carousel light-button' onClick={prevSlide}>{'<'}</Button> */}
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${index === currentSlide ? 'active' : ''}`}
        >
          {slide}
        </div>
      ))}
      {
        slideChangeButton? 
            <Button className='button-carousel light-button' onClick={nextSlide}>
                <i className="bi bi-caret-right-fill"></i>        
            </Button>
        : null
      }
      {/* <Button className='button-carousel light-button' onClick={nextSlide}>{'>'}</Button> */}
      <div className="indicators">
        <div className='indicator-play-pause' onClick={togglePause}>
            {
                isPaused?
                    <i className="bi bi-play-circle"></i>
                :
                    <i className="bi bi-pause-circle"></i>
            }
        </div>
        {slides.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
