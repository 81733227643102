import { shopHostname } from "../../pages/Constants"
import { ButtonTakeMeToUrl } from "../button/Button"
import { Link } from "../extraFunctions/Link";
import './Banner.scss';

export const ShopBanner = () =>{
    return (
        <div className='background-banner' style={{
                width:'100%',
                position:'absolute', 
                top:'0', 
                left:'0',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    maxWidth:'800px',
                    width:'100%'
                }}
            >
                <div style={{
                        display:'flex', 
                        justifyContent:'space-around', 
                        alignItems:'center', 
                        padding:'5px',
                        width:'100%'
                    }}
                >
                    <div style={{textAlign:'left', width:'100%'}}>
                            <span style={{color:'white', fontSize:'1rem', fontWeight:'600'}}>Experience the Beauty of Natural Stone</span>
                            {/* <br/>
                            <span style={{color:'white', fontSize:'0.8rem'}}>Create Lasting Impressions</span> */}
                    </div>
                    <div style={{marginLeft:'10px', width:'100%', display:'flex', justifyContent:'center'}}>
                        {/* <a href="https://shop.sgmarbles.com"> */}
                            {/* <button style={{background:'#24422a', color:'white', padding:'10px 25px', border:'3px solid white', borderRadius:'50px', fontSize:'1.05rem', cursor:'pointer'}}>Shop Now</button> */}
                            <ButtonTakeMeToUrl 
                                className={'light-button'}
                                takeMeTo={{
                                    to:'',
                                    hostname:shopHostname 
                                }}
                                style={{fontWeight:'600'}}                    
                            >
                                Shop Now
                            </ButtonTakeMeToUrl>
                        {/* </a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const HelpBanner =() =>{
    return (
        <div style={{
                width:'100%',
                position:'absolute', 
                top:'0', 
                left:'0',
                backgroundImage:'linear-gradient(270deg, rgba(0,172,1, 0.4), #24422a)',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
            }}
        >
            <div style={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    maxWidth:'1000px',
                    // background:'pink',
                    width:'100%'
                }}
            >
                <div style={{
                        display:'flex', 
                        justifyContent:'space-around', 
                        alignItems:'center', 
                        padding:'5px',
                        width:'100%'
                    }}
                >
                    <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <span style={{color:'white', fontSize:'1rem', fontWeight:'600'}}>Need help? <em><Link to='contact' style={{color:'white', textDecoration:'none'}}>Contact us</Link></em></span>
                            {/* <br/>
                            <span style={{color:'white', fontSize:'0.8rem'}}>Create Lasting Impressions</span> */}
                    </div>
                    {/* <div style={{marginLeft:'10px', width:'100%', display:'flex', justifyContent:'flex-end'}}>
                        <a href="https://shop.sgmarbles.com">
                            <button style={{background:'#24422a', color:'white', padding:'10px 25px', border:'3px solid white', borderRadius:'50px', fontSize:'1.05rem', cursor:'pointer'}}>Shop Now</button>
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    )
}