import React from 'react';
import "../../Collection.scss"; 

const products = {
    "600x600mm": [
        {
            title: "Sandy Beige",
            image_title: "sandy_beige",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '600x600',
        },
        {
            title: "Sandy Nero",
            image_title: "sandy_nero",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '600x600',
        },
        {
            title: "Sandy Mist",
            image_title: "sandy_mist",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '600x600',
        },

    ],
    "600x600 15mm": [
        {
            title: "Galaxy Grey",
            image_title: "galaxy_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '600x600 15mm',
        },
    ],
    "800x2400 15mm": [
        {
            title: "Royal White",
            image_title: "royal_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Royal Brown",
            image_title: "royal_brown",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Royal Black",
            image_title: "royal_black",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Ultima Nero",
            image_title: "ultima_nero",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Ultima Beige",
            image_title: "ultima_beige",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Ultima White",
            image_title: "ultima_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Ultima Brown",
            image_title: "ultima_brown",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Ultima Grey",
            image_title: "ultima_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Royal Grey",
            image_title: "royal_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Ultima Verde",
            image_title: "ultima_verde",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
        {
            title: "Sandy Verde",
            image_title: "sandy_verde",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "nucera",
                series: "",
            }
        },
        {
            title: "Sandy Beige",
            image_title: "sandy_beige",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "nucera",
                series: "",
            }
        },
        {
            title: "Sandy Grey",
            image_title: "sandy_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "nucera",
                series: "",
            }
        },
        {
            title: "Sandy Nero",
            image_title: "sandy_nero",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "nucera",
                series: "",
            }
        },
        {
            title: "Sandy Brown",
            image_title: "sandy_brown",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "nucera",
                series: "",
            }
        },
        {
            title: "Sandy White",
            image_title: "sandy_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "nucera",
                series: "",
            }
        },
        {
            title: "Cosmic Black",
            image_title: "cosmic_black",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "cosmic",
            }
        },
        {
            title: "Cosmic Walnut",
            image_title: "cosmic_Walnut",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "cosmic",
            }
        },
        {
            title: "Cosmic Grey",
            image_title: "cosmic_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "cosmic",
            }
        },
        {
            title: "Cosmic Brown",
            image_title: "cosmic_brown",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "cosmic",
            }
        },
        {
            title: "Atom White",
            image_title: "atom_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "atom",
            }
        },
        {
            title: "Atom Grey",
            image_title: "atom_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "atom",
            }
        },
        {
            title: "Ionic Uni Crema",
            image_title: "ionic_uni_crema",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "emcer",
                series: "ionic",
            }
        },
    ],
    "800x3000 15mm": [
        {
            title: "Ultima White",
            image_title: "ultima_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: '800x2400 15mm',
            otherInfo: {
                brand: "exxaro",
                series: "",
            }
        },
    ]
}

const FullBodyVitrifiedCollection = () => {
    return (
        <div className="product-collection">
            {/* <h2>Our Collection</h2> */}
            {Object.entries(products).map(([category, items]) => (
                <div className="category" id={category} key={category} style={{marginTop:'100px'}}>
                    <h3>{category.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h3>
                    <div className='flex-cc'>
                        <div className='category-container'>
                            <ul className='product-gallery product-list'>
                                {
                                    items.map((item, index) => {
                                        if(item.link){
                                            return (
                                                <a href={`collection/${item.collection}/${item.link}`} key={index} className='list-item'>
                                                    <img className='product-img collection-img' src={`/assets/images/collections/${item.collection}/${item.imageTitle}.${item.imgFileType}`} />
                                                    <div style={{position:'absolute', background:'rgba(255,255,255,0.7)', width:'100%', bottom:0, padding:'10px 0px', borderBottomRightRadius:'10px', borderBottomLeftRadius:'10px'}}>
                                                        <span style={{fontWeight:600}}>{item.title}</span>
                                                    </div>
                                                </a>
                                            )
                                        }else{
                                            return (
                                                <li key={index} className='list-item'>
                                                    <img className='product-img collection-img' src={`/assets/images/collections/${item.collection}/${item.imageTitle}.${item.imgFileType}`} />
                                                    <div style={{position:'absolute', background:'rgba(255,255,255,0.7)', width:'100%', bottom:0, padding:'10px 0px', borderBottomRightRadius:'10px', borderBottomLeftRadius:'10px'}}>
                                                        <span style={{fontWeight:600}}>{item.title}</span>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FullBodyVitrifiedCollection;
