import { Helmet } from "react-helmet";
import {ReactComponent as TrustBadge} from '../../assets/svg/trust-badge.svg';
import {ReactComponent as Secure} from '../../assets/svg/secure.svg';

import './Payments.scss';
import { AuthenticBadge } from "../../components/badges/AuthenticBadge";
import { FrequentlyAskedBadge } from "../../components/badges/FrequentlyAskedBadge";
import { webTitle } from "../Constants";
import { SurveyDislikeButton, SurveyLikeButton } from "../../components/button/SurveyButtons";
import { WasThisHelpful } from "../../components/survey/SurveyQuestions";

export const Faq = () =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <Helmet>
                    <title>Help/FAQ - {webTitle}</title>
                </Helmet>
                <section>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Frequently Asked Questions {'(FAQ)'}</h1>
                        {/* <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            <span style={{fontWeight:'600'}}>28 October, 2024</span>
                        </span> */}
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    
                    {/* <div className="centered-banner full-width">
                        <div style={{position:'relative', width:'200px', height:'200px', background:'pink', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <TrustBadge />
                            <div style={{position:'absolute', width:'40px', height:'40px'}}>
                                <Secure />
                            </div>
                        </div>
                    </div> */}
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <FrequentlyAskedBadge />
                    </div>

                    {/* <p style={{fontWeight:'600', fontStyle:'italic'}}>This return policy is only applicable for purchases made & delivered in India</p> */}
                    <article className="payments-article">
                        <div className="qna">
                            <h4 className="q">What products do you offer?</h4>
                            <span className="a">
                                We offer a wide variety of products including marble, granite, tiles, grouts & adhesives, and more. You can browse our product collections on our website.
                            </span>
                        </div>
                        <div className="qna">
                            <h4 className="q">How can I contact customer support?</h4>
                            <span className="a">
                            You can contact our customer support team via email or phone. You can also fill up our contact <a href='/contact'>form</a>, and we will get back to you.                        
                            </span>
                        </div>
                        <div className="qna">
                            <h4 className="q">Do you offer international shipping?</h4>
                            <span className="a">
                            Yes, we offer international shipping to most countries. Shipping costs and delivery times may vary depending on your location.
                            </span>

                            {/* <div className="detailed-answer">
                                <h5 className="title">Credit cards</h5>
                                <p>
                                    We accept payments made using Visa, MasterCard and American Express credit cards. To pay using your credit card at checkout, you will need your card number, expiry date, three-digit CVV number (found on the backside of your card). After entering these details, you will be redirected to the bank's page for entering the online 3D Secure password.
                                </p>
                                <h5 className="title">Debit cards</h5>
                                <p>
                                    We accept payments made using Visa, MasterCard and Maestro debit cards. To pay using your debit card at checkout, you will need your card number, expiry date (optional for Maestro cards), three-digit CVV number (optional for Maestro cards). You will then be redirected to your bank's secure page for entering your online password (issued by your bank) to complete the payment.
                                </p>
                            </div> */}
                        </div>

                        <div className="qna">
                            <h4 className="q">How can I track my order?</h4>
                            <span className="a">
                            Once your order has been shipped, we will send you a tracking number via email. You can use this number to track your order on our website or the carrier's website.
                            </span>
                        </div>
                        <div className="qna">
                            <h4 className="q">What if my order arrives damaged?</h4>
                            <span className="a">If your order arrives damaged, please contact our customer support team within 48 hours of receiving your order. We will assist you in resolving the issue as quickly as possible.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">What is your return policy?</h4>
                            <span className="a">We only accept returns for orders delivered in <strong>India</strong>. The product must be in its original condition and packaging. Please checkout our <a href='/return-policy'>return policy</a> and <a href='/help/refunds-cancellation'>refunds & cancellation</a> page for more details.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">How do I initiate a return?</h4>
                            <span className="a">To initiate a return, please <a href='/contact'>contact</a> our customer support team with your order number and reason for return. They will guide you through the process.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">When will I receive my refund?</h4>
                            <span className="a">Refunds are processed within 14 business days after we receive your returned item. You will receive an email confirmation once your refund has been processed.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">Is my payment information secure?</h4>
                            <span className="a">Yes, we have partnered with one of the best payment gateway partners. They use secure encryption technology to protect your payment information. Your data is handled with the utmost care and security.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">How do I find the right product for my needs?</h4>
                            <span className="a">Our product pages provide detailed descriptions and specifications for each item. If you need further assistance, our customer support team is happy to help you choose the right product.</span>
                        </div>
                        <div className="qna">
                            <h4 className="q">Can I request samples before purchasing?</h4>
                            <span className="a">No, we do not offer samples for our products.</span>
                        </div>
                    </article>

                    <WasThisHelpful page={window.location.pathname} submitResponseVisible={true}/>
                </section>
            </div>
        </div>
    )
}