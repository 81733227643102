import {ReactComponent as FaqBadge} from '../../assets/svg/faq2.svg';
import './badge.scss';

export const FrequentlyAskedBadge =() =>{
    return (
        <div style={{position:'relative', width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{width:'100px', height:'100px'}}>
                <FaqBadge />
            </div>
            <div style={{color:'#24422a', position:'absolute', bottom:20}}>
                <span className='narrow-font'>Frequently Asked Questions</span>
            </div>
        </div>
    )
}