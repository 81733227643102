import React from 'react';
import "./Collection.scss"; 

const products = {
    // marble: ["Agaria White", "Morwad White", "Toronto", "Pink", "Green", "Banswara White"],
    marbles: [
        {
            title: "Agaria White",
            imageTitle: "agaria_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'marbles',
        },
        {
            title: "Morwad White",
            imageTitle: "morwad_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'marbles',
        },
        {
            title: "Toronto",
            imageTitle: "toronto",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'marbles',
        },
        {
            title: "Pink",
            imageTitle: "pink_marble",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'marbles',
        },
        {
            title: "Green",
            imageTitle: "green_marble",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'marbles',
        },
        // {
        //     title: "Banswara White",
        //     imageTitle: "banswara_white",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'marbles',
        // },
    ],
    granites: [
        // {
        //     title: "Jet Black",
        //     imageTitle: "jet_black",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        {
            title: "Sira Grey",
            imageTitle: "sira_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Silver Grey",
            imageTitle: "silver_grey",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Cheema Pink",
            imageTitle: "cheema_pink",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Rozy Pink",
            imageTitle: "rozy_pink",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        // {
        //     title: "Platinum White",
        //     imageTitle: "platinum_white",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        {
            title: "R. Nyaas",
            imageTitle: "r_nyaas",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Mudgal Pink",
            imageTitle: "mudgal_pink",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "China White",
            imageTitle: "china_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "R. Paradiso Brown",
            imageTitle: "r_paradiso_brown",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "R. Cateye",
            imageTitle: "r_cateye",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Tiger",
            imageTitle: "tiger",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        // {
        //     title: "Jeerawal White",
        //     imageTitle: "jeerawal_white",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // {
        //     title: "S. White",
        //     imageTitle: "s_white",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        {
            title: "Black Markino",
            imageTitle: "black_markino",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "R. Black",
            imageTitle: "r_black",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Majestic Black",
            imageTitle: "majestic_black",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Marie Gold",
            imageTitle: "marie_gold",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        // {
        //     title: "Himalayan Blues",
        //     imageTitle: "himalayan_blues",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        {
            title: "Paradiso Black",
            imageTitle: "paradiso_black",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        // {
        //     title: "Paradiso Brown",
        //     imageTitle: "paradiso_brown",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        {
            title: "Ruby Red",
            imageTitle: "ruby_red",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Juprana Pink",
            imageTitle: "juprana_pink",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Chilli Red",
            imageTitle: "chilli_red",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        // {
        //     title: "Madurai Gold",
        //     imageTitle: "madurai_gold",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        {
            title: "Kangyam Gold",
            imageTitle: "kangyam_gold",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Kashmir White",
            imageTitle: "kashmir_white",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        {
            title: "Tan Brown",
            imageTitle: "tan_brown",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'granites',
        },
        // {
        //     title: "Black Galaxy",
        //     imageTitle: "black_galaxy",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // {
        //     title: "Steel Grey",
        //     imageTitle: "steel_grey",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // {
        //     title: "Leather Brown",
        //     imageTitle: "leather_brown",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // {
        //     title: "Crystal Blue",
        //     imageTitle: "crystal_blue",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // {
        //     title: "Diamond Brown",
        //     imageTitle: "diamond_brown",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // {
        //     title: "Noor Black",
        //     imageTitle: "noor_black",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'granites',
        // },
        // "Jet Black", 
        // "Sira Grey", 
        // "Silver Grey", 
        // "Cheema Pink", 
        // "Rozy Pink", 
        // "Platinum White", 
        // "Jeerawal White", 
        // "S. White", 
        // "Black Markino", 
        // "R. Black", 
        // "Majestic Black", 
        // "Marie Gold", 
        // "Himalayan Blues", 
        // "Paradiso Black", 
        // "Paradiso Brown", 
        // "Ruby Red",
        // "Juprana Pink", 
        // "Chilli Red", 
        // "Madurai Gold", 
        // "Kashmir White", 
        // "Tan Brown",
        // "Black Galaxy",
        // "Steel Grey",
        // "Leather Brown",
        // "Crystal Blue",
        // "Diamond Brown",
        // "Noor Black",
    ],
    tiles: [  
        // {
        //     title: "Ceramic",
        //     imageTitle: "ceramic",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'tiles',
        // },
        // {
        //     title: "Vitrified",
        //     imageTitle: "vitrified",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'tiles',
        // },
        {
            title: "Wall Tiles",
            imageTitle: "wall_tiles",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'tiles',
        },
        {
            title: "Floor Tiles",
            imageTitle: "floor_tiles",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'tiles',
        },
        {
            title: "Full Body Vitrified",
            imageTitle: "full_body_vitrified",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'tiles',
            // link: 'full-body-vitrified',
        },
        {
            title: "Double Charge",
            imageTitle: "double_charge",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'tiles',
        },
        // "Ceramic", "Vitrified", "Full Body Vitrified", "Double Charge"
    ],
    // quartz: [
    //     {
    //         title: "Citilite",
    //         imageTitle: "citilite",
    //         imgFileType: "jpg",
    //         imgRes: {
    //             width: 500,
    //             height: 500,
    //         },
    //         collection: 'quartz',
    //     },
    // ],
    groutsAndAdhesives: [  
        {
            title: "Epoxy Grouts",
            imageTitle: "epoxy_grouts",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: "grouts_and_adhesives",
        },
        {
            title: "Cementitious Grouts",
            imageTitle: "cementitious_grouts",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'grouts_and_adhesives',
        },
        {
            title: "Tile Adhesives",
            imageTitle: "tile_adhesives",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'grouts_and_adhesives',
        },
        {
            title: "Grout Mixture",
            imageTitle: "grout_mixture",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'grouts_and_adhesives',
        },
        {
            title: "Epoxy Sparkles",
            imageTitle: "epoxy_sparkles",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'grouts_and_adhesives',
        },
        // {
        //     title: "Tile Bonders",
        //     imageTitle: "tile_bonders",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'grouts_and_adhesives',
        // },
        // "Epoxy Grout", "Cement Grout", "Tile Adhesive", "Grout Mixture", "Tile Bonders"
    ],
    others: [
        {
            title: "Tile Cleaners",
            imageTitle: "jktylo_clearmate",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'others',
        },
        // {
        //     title: "Grout Spacers",
        //     imageTitle: "grout_spacers",
        //     imgFileType: "jpg",
        //     imgRes: {
        //         width: 500,
        //         height: 500,
        //     },
        //     collection: 'others',
        // },
        {
            title: "Marble Chips",
            imageTitle: "marble_chips",
            imgFileType: "jpg",
            imgRes: {
                width: 500,
                height: 500,
            },
            collection: 'others',
        },
        // "Tile Cleaners", "Grout Spacers", "Marble Chips"
    ]
};

const Collection = () => {
    return (
        <div className="product-collection">
            {/* <h2>Our Collection</h2> */}
            {Object.entries(products).map(([category, items]) => (
                <div className="category" id={category} key={category} style={{marginTop:'100px'}}>
                    <h3>{category.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</h3>
                    <div className='flex-cc'>
                        <div className='category-container'>
                            <ul className='product-gallery product-list'>
                                {
                                    items.map((item, index) => {
                                        if(item.link){
                                            return (
                                                <a href={`collection/${item.collection}/${item.link}`} key={index} className='list-item'>
                                                    <img className='product-img collection-img' src={`/assets/images/collections/${item.collection}/${item.imageTitle}.${item.imgFileType}`} />
                                                    <div style={{position:'absolute', background:'rgba(255,255,255,0.7)', width:'100%', bottom:0, padding:'10px 0px', borderBottomRightRadius:'10px', borderBottomLeftRadius:'10px'}}>
                                                        <span style={{fontWeight:600}}>{item.title}</span>
                                                    </div>
                                                </a>
                                            )
                                        }else{
                                            return (
                                                <li key={index} className='list-item'>
                                                    <img className='product-img collection-img' src={`/assets/images/collections/${item.collection}/${item.imageTitle}.${item.imgFileType}`} />
                                                    <div style={{position:'absolute', background:'rgba(255,255,255,0.7)', width:'100%', bottom:0, padding:'10px 0px', borderBottomRightRadius:'10px', borderBottomLeftRadius:'10px'}}>
                                                        <span style={{fontWeight:600}}>{item.title}</span>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Collection;
