import { Helmet } from "react-helmet";
import { storeLocations, webTitle } from "./Constants";
import { Link } from "../components/extraFunctions/Link";
import { ReactComponent as Location } from "../assets/svg/location.svg";
import { Button } from "../components/button/Button";
import { FlexibleDiv } from "../components/ui/div/Div";
import Tag from "../components/Tag/Tag";

const StoreLocation =() =>{
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div style={{display:'flex', justifyContent:'center'}}>
            <Helmet>
                <title>Store location- {webTitle}</title>
            </Helmet>
            <div style={{margin:'0 30px', paddingTop:'50px', maxWidth:'800px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center'}}>
                <section>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Store location</h1>
                        {/* <span style={{textAlign:'right', fontSize: '0.8em', color:'rgb(65, 65, 65)', fontStyle:'italic'}}>Updated on&nbsp; 
                            <span style={{fontWeight:'600'}}>28 October, 2023</span>
                        </span> */}
                    </div>
                    
                    <div style={{height:'50px'}}></div>
                    
                    <div className="centered-banner full-width" style={{background:'#c6e7cc'}}>
                        <div style={{position:'relative', width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <div style={{width:'100px', height:'100px'}}>
                                <Location />
                            </div>
                            <div style={{color:'#24422a', position:'absolute', bottom:20}}>
                                <span className='narrow-font'>Get Directions on Google Maps</span>
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div className="full-width">
                        <br/><br/>
                        {
                            storeLocations.length?
                                storeLocations.map((location, index)=>{
                                    let closed = '';
                                    if(location.closedOnDays.length){
                                        closed = location.closedOnDays.join(', ');
                                    }
                                    return (
                                        <FlexibleDiv key={index} style={{borderTop:index?'1px solid #eee':''}}>
                                            <div>
                                                <p style={{textTransform:'capitalize', fontWeight:'600'}}><i className="bi bi-geo-alt"></i> {location.storeName}</p>
                                                <div style={{display:'flex', flexDirection:'column', padding:'0px 20px 20px 0px'}}>
                                                    <span><span style={{fontWeight:'600'}}>Address:</span> {location.storeAddress}</span>
                                                    <span><span style={{fontWeight:'600'}}>Contact:</span> {location.storeContact}</span>
                                                    {
                                                        closed.length?
                                                            <div>
                                                                <span style={{fontWeight:'600'}}>Closed on:&nbsp;</span> 
                                                                    <Tag label={closed} fontSize={'1rem'} bgColor='darkred' textTransform={"capitalize"}/>
                                                            </div>
                                                        : null      
                                                    }
                                                </div>
                                            </div>
                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                <a href={location.directionsRedirect}>
                                                    <Button className={'light-button'} style={{fontWeight:'600'}}><span>Get Directions</span></Button>
                                                </a>
                                            </div>
                                        </FlexibleDiv>
                                    )
                                })
                            :
                                <p>Sorry, at this moment access is restricted for visitors. Kindly reach out to us <Link to='contact'>here</Link>. We will be happy to help you.</p>
                        }
                    </div>
                    {/* <div style={{background:'#c6e7cc', padding:'20px 50px'}}>
                        <p style={{fontSize:'1.5rem', fontWeight:'600', color:'#'}}>
                            <Link to='collections' hostname={shopHostname}>
                                <span>Explore</span>
                            </Link>
                            &nbsp;our collection of products and discover the charm and elegance of natural stones.
                        </p>
                    </div> */}
                    
                    {/* <WasThisHelpful page={window.location.pathname} submitResponse={true}/> */}
                </section>
            </div>
        </div>
    )
}

export default StoreLocation;