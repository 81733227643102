import {ReactComponent as SecureBadge} from '../../assets/svg/secure.svg';
import './badge.scss';

export const SecurePaymentsBadge =() =>{
    return (
        <div style={{position:'relative', width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <div style={{width:'100px', height:'100px'}}>
                <SecureBadge />
            </div>
            <div style={{color:'#24422a', position:'absolute', bottom:20}}>
                <span className='narrow-font'>Secure Payments</span>
            </div>
        </div>
    )
}