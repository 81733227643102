import {ReactComponent as TrustBadge} from '../../assets/svg/trust-badge.svg';
import './badge.scss';

export const AuthenticBadge =() =>{
    return (
        <div style={{position:'relative', width:'200px', height:'200px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <TrustBadge />
            <div style={{position:'absolute'}}>
                <span style={{color:'white', fontSize:'1.1rem', textAlign:'center', textTransform:'uppercase'}} className="badge-center-text narrow-font">100% authentic</span>
            </div>
            <div style={{color:'#24422a', position:'absolute', bottom:20}}>
                <span className='narrow-font'>Crafted from Natural Stone</span>
            </div>
        </div>
    )
}